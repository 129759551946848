import React from 'react';
import { ReactComponent as HomeIcon } from '../assets/icons/home-icon.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search-icon.svg';

const Navigation = ({ toggleSearch, toggleToc, toggleSplash, searchVisible, tocVisible }) => {
  return (

<nav>
<div id="home-nav" onClick={toggleSplash} className={(searchVisible || tocVisible) ? 'nav-item' : 'nav-item'}>
  <HomeIcon />
</div>
<div id="search-nav" onClick={toggleSearch} className={searchVisible ? 'nav-item active' : 'nav-item'}>
  <SearchIcon />
</div>
<div id="toc-nav" onClick={toggleToc} className={tocVisible ? 'nav-item active' : 'nav-item'}>
  <span></span>
</div>
</nav>

);
};

export default Navigation;